import React from 'react';
import './Spinner.css';

function Spinner() {
  return (
    
      <div className="spinner" id="loading"></div>
   
  );
}

export default Spinner;
