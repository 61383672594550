import React,{lazy} from 'react'
import './index.css';
import './App.css';
import { Route, BrowserRouter as Router, Routes,  } from 'react-router-dom'
import Header from './components/Header/Header.js'
// import Popup from './components/popup/popup.js';
import Products from './components/Pages/Products.js'
import AdminForm from './components/AdminForm/AdminForm.js'
// const Contact = lazy(()=>import("./components/Pages/Contact.js"));
// const Home = lazy(()=>import("./components/Pages/Home.js"));
// const About = lazy(()=>import("./components/Pages/About.js"));
// const AdminLog = lazy(()=>import("./components/AdminLogIn/AdminLog.js"));

// const Products = lazy(()=>import("./components/Pages/Products.js"));



function App() {
  // const [isLogged, setIsLogged] = useState(false);
  return (
  <>

    {/* <Popup/> */}
    <Header/>
    <Router>
<Routes>
<Route path='/' element={<Products/>} />
<Route path='/adminform' element={<AdminForm />} />

</Routes>

    </Router>
    {/* <Products/> */}
  </>



  
  );
}



export default App;

