import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SkeletonCard from "../Skeleton/SkeletonCard"; // Import the SkeletonCard component
import "./Card.css";
import { API_URL } from "../../config";

const Card = ({ products }) => {
  const [displayedItems, setDisplayedItems] = useState([]); // Displayed items based on category
  const [hasMore, setHasMore] = useState(true); // Flag to indicate if more items can be loaded
  const [loading, setLoading] = useState(true); // Flag to indicate if data is loading
  const [imageLoading, setImageLoading] = useState({}); // Track loading state for each image

  useEffect(() => {
    // Load initial items on component mount based on category
    filterProductsByCategory("All");
  }, [products]);

  const loadMoreItems = () => {
    // Simulate API call delay
    setTimeout(() => {
      const newItems = products.slice(
        displayedItems.length,
        displayedItems.length + 6
      ); // Load 6 more items
      setDisplayedItems([...displayedItems, ...newItems]);
      setLoading(false);
      if (displayedItems.length + newItems.length >= products.length) {
        setHasMore(false); // No more items to load
      }
    }, 1500);
  };

  const filterProductsByCategory = (category) => {
    setLoading(true); // Set loading to true while filtering
    setDisplayedItems([]); // Clear displayed items
    setHasMore(true); // Reset hasMore flag

    setTimeout(() => {
      const filteredItems =
        category === "All"
          ? products.slice(0, 6)
          : products
              .filter((product) => product.category === category)
              .slice(0, 6);
      setDisplayedItems(filteredItems);
      setHasMore(filteredItems.length < products.length);
      setLoading(false); // Set loading to false after filtering
    }, 500);
    // Simulated delay for better UX
  };

  const handleImageLoad = (id) => {
    setImageLoading((prev) => ({ ...prev, [id]: false }));
  };

  return (
    <section className="mx-auto p-5 cards-section block">
      <InfiniteScroll
        dataLength={displayedItems.length} // This is important to indicate the current number of items
        next={loadMoreItems} // Function to call when reaching the end of the list
        hasMore={hasMore} // Flag to indicate if there are more items to load
        loader={<div><h3>Loading ....</h3></div>} // Center the loader component
        endMessage={<p>No more items to load</p>} // Message displayed when all items are loaded
        className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-10"
      >
        {loading
          ? [...Array(6)].map((_, index) => <SkeletonCard key={index} />) // Show skeleton cards while loading
          : displayedItems.map(
              ({ _id, name, link, category, image, rating, shortDescription }) => (
                <div
                  className="rounded overflow-hidden shadow-lg bg-white m-4 card-section"
                  key={_id}
                >
                  <LazyLoadImage
                    className="h-52 w-full object-cover transition duration-300 ease-in-out hover:scale-105"
                    src={`${API_URL}/${image}`}
                    alt="Product"
                    effect="blur"
                    beforeLoad={() => setImageLoading((prev) => ({ ...prev, [_id]: true }))}
                    afterLoad={() => handleImageLoad(_id)} // Set loading to false after image is loaded
                  />
                  <div className="fixed-section">
                    <div className="px-6 py-4">
                      <div className="font-bold text-xl mb-2 hover:underline name-section flex">
                        <a
                          href={link}
                          target="_blank"
                          className="cursor-pointer"
                          rel="noreferrer"
                        >
                          {name}
                        </a>
                        <svg
                          className="w-3 h-3 ms-2.5 rtl:rotate-[270deg] hover:visible"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 18 18"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
                          />
                        </svg>
                      </div>
                      <p className="text-gray-700 text-base des-section">
                        {shortDescription}
                      </p>
                    </div>
                    <div className="px-6 pt-1 pb-2 flex rate-btn">
                      <div className="mt-2.5 mb-5 flex items-center">
                        <span className="mr-2 rounded bg-yellow-200 px-2.5 py-0.5 text-xs font-semibold rate-section">
                          {rating}
                        </span>
                        {[...Array(5)].map((star, index) => {
                          let starClass = "text-gray-300";

                          // Check for full stars
                          if (index < Math.floor(rating)) {
                            starClass = "text-yellow-300"; // Fully filled star
                          } else if (
                            index === Math.floor(rating) &&
                            rating % 1 !== 0
                          ) {
                            starClass = "text-yellow-300"; // Half filled star
                          }

                          return (
                            <svg
                              key={index}
                              aria-hidden="true"
                              className={`h-5 w-5 ${starClass}`}
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10 1l2.6 6.5h6.8l-5.3 4.4 2.1 6.7-5.6-4.3-5.6 4.3 2.1-6.7-5.3-4.4h6.8z" />
                            </svg>
                          );
                        })}
                      </div>
                      <a
                        href={link}
                        target="_blank"
                        className="cursor-pointer"
                        rel="noreferrer"
                      >
                        <button className="inline-block border border-[#087096] text-[#087096] cursor-pointer flex-grow font-bold text-xs h-10 leading-[38px] mx-2 px-6 whitespace-nowrap transition duration-300 ease-in-out rounded-sm hover:bg-[#087096] hover:text-white btn-section">
                        Live Preview
                        <svg
                          className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 18 18"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
                          />
                        </svg>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            ))}
      </InfiniteScroll>
    </section>
  );
};

export default Card;
