import React, {  useState  } from "react";
// import { Link } from "react-router-dom";
import myImage from '../../assect/logo/logo.png';
import './Header.css'
const Header = () => {

  const [menuOpen, setMenuOpen] = useState(false);
 
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="bg-gray-800 text-white">
      <nav className="container mx-auto px-4 py-4 flex items-center justify-between">
        <div className="flex items-center">
          <div to="/products" className="text-xl font-bold logo-box cursor-pointer">
           <img src={myImage} alt="logo"   />
          </div>
        </div>
        <div className="hidden lg:flex items-center space-x-4">
     
          <div to="#" className="nav-link">
          <i class="fas fa-envelope"></i>
         <p> info@tpdigitaltechnologies.com</p>
          </div>
          <div to="/products" className="nav-link">
          <i class="fas fa-phone rotated-icon" ></i>
        <p>  +91-9934974671 +91-9830108958</p>
          </div>
    
        </div>
        <div className="flex lg:hidden items-center">
          <button
            className="block focus:outline-none"
            onClick={toggleMenu}
          >
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path
                fillRule="evenodd"
                d="M3 4h14a1 1 0 110 2H3a1 1 0 010-2zm0 6h14a1 1 0 010 2H3a1 1 0 010-2zm0 6h14a1 1 0 010 2H3a1 1 0 010-2z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </nav>
      {menuOpen && (
        <nav className="bg-gray-800 py-2">
          <ul className="container mx-auto flex flex-col items-center space-y-4">
            {/* <li>
              <Link to="/" className="nav-link" onClick={toggleMenu}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="nav-link" onClick={toggleMenu}>
                About
              </Link>
            </li>
            <li>
              <Link to="/contact" className="nav-link" onClick={toggleMenu}>
                Contact
              </Link>
            </li> */}
            <li>
            <div to="#" className="nav-link">
          <i class="fas fa-envelope"></i>
         <p> info@tpdigitaltechnologies.com</p>
          </div>
         
            </li>
            <li>
            <div to="/products" className="nav-link">
          <i class="fas fa-phone rotated-icon" ></i>
        <p>  +91-9934974671 +91-9830108958</p>
          </div>
            </li>
            {/* <li>
           { isLogged ? (  <Link to="/login" className="nav-link" onClick={toggleMenu}>
                Logout
              </Link>) : (  <Link to="/login" className="nav-link"  onClick={() => {
      toggleMenu();
      handleLogout();
    }}>
                Login
              </Link>)}
            </li> */}
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;