import React from 'react';

const SkeletonCard = () => {
  return (
    <div className="rounded overflow-hidden shadow-lg bg-white m-4 card-section animate-pulse">
      <div className="h-52 w-full bg-gray-200"></div>
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2 h-6 bg-gray-200 rounded"></div>
        <p className="text-gray-700 text-base h-4 bg-gray-200 rounded w-3/4"></p>
      </div>
      <div className="px-6 pt-1 pb-2 flex rate-btn">
        <div className="mt-2.5 mb-5 flex items-center">
          <span className="mr-2 rounded bg-gray-200 px-2.5 py-0.5 text-xs font-semibold"></span>
          {[...Array(5)].map((_, index) => (
            <svg
              key={index}
              aria-hidden="true"
              className="h-5 w-5 text-gray-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 1l2.6 6.5h6.8l-5.3 4.4 2.1 6.7-5.6-4.3-5.6 4.3 2.1-6.7-5.3-4.4h6.8z" />
            </svg>
          ))}
        </div>
        <button className="inline-block border border-gray-300 text-gray-300 cursor-pointer flex-grow font-bold text-xs h-10 leading-[38px] mx-2 px-6 whitespace-nowrap rounded-sm bg-gray-200"></button>
      </div>
    </div>
  );
};

export default SkeletonCard;
