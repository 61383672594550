import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "../../config";
// import { useNavigate } from "react-router-dom";
const AdminForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    category: "",
    price: "",
    rating: "",
    shortDescription: "",
    image:null,
    link:""
  });
  // const navigate = useNavigate();
  // useEffect(()=>{
  //  const token = localStorage.getItem('authToken');
  //  if(!token){
  //   navigate('/login');
  //  }
  // },[navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (event) => {
      const file = event.target.files[0];
      setFormData({ ...formData, image: file });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Form Data:", formData);
    const form = new FormData();
    form.append("name", formData.name);
    form.append("category", formData.category);
    form.append("price", formData.price);
    form.append("rating", formData.rating);
    form.append("shortDescription", formData.shortDescription);
    form.append("image", formData.image);
    form.append("link", formData.link);
    try {
      await axios.post(`${API_URL}/api/addproducts`, form);
      alert("Your Product Is Added");
      setFormData({
        name: "",
        category: "",
        price: "",
        rating: "",
        shortDescription: "",
        image:null,
        link:""
      });
      // navigate("/products");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="admin-panel-form bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-md mx-auto">
      <h2 className="text-xl font-bold mb-4">Add Product</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Name:
          </label>
          <input
            id="name"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Product Name"
            required
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="link"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Link:
          </label>
          <input
            id="link"
            type="text"
            name="link"
            value={formData.link}
            onChange={handleInputChange}
            placeholder="Product Url..."
            required
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        {/* <div className="mb-4">
          <label
            htmlFor="category"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Category:
          </label>
          <input
            id="category"
            type="text"
            name="category"
            value={formData.category}
            onChange={handleInputChange}
            placeholder="Category Name"
            required
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div> */}
        <div className="mb-4">
            <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label>
  <select  id="category" name="category" onChange={handleInputChange} value={formData.category} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
    <option defaultValue>Choose a Category</option>
    <option value="Mobile App Project">Mobile App Project</option>
    <option value="WordPress Project">WordPress Project</option>
    <option value="Reactjs Project">Reactjs Project</option>
    <option value="Nextjs Project">Nextjs Project</option>
    <option value="Static Project">Static Project</option>
    <option value="PHP Project">PHP Project</option>
    {/* <option value="React Native">React Native</option>// */}
  </select>
        </div>
        


        <div className="mb-4">
          <label
            htmlFor="shortDescription"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Short Description:
          </label>
          <input
            id="shortDescription"
            type="text"
            name="shortDescription"
            value={formData.shortDescription}
            onChange={handleInputChange}
            placeholder="Short Description"
            required
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="price"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Price:
          </label>
          <input
            id="price"
            type="number"
            name="price"
            value={formData.price}
            onChange={handleInputChange}
            placeholder="Price"
            required
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="rating"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Rating:
          </label>
          <input
            id="rating"
            type="number"
            name="rating"
            value={formData.rating}
            onChange={handleInputChange}
            placeholder="Rating"
            required
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        {/* <div className="mb-4">
                    <label htmlFor="Description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Long Description:</label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        placeholder="Long Description"
                        rows="4"
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    ></textarea>
                </div> */}
        <div className="mb-4">
                    <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Image:</label>
                    <div className="flex items-center mt-1">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            id="image"
                            className="hidden"
                            required
                        />
                        <label
                            htmlFor="image"
                            className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition duration-300"
                        >
                            Choose Image
                        </label>
                        {formData.image && <span className="block text-sm text-gray-600 ml-2">{formData.image.name}</span>}
                    </div>
                </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg transition duration-300"
          >
            Add Product
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminForm;
