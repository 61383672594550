import React, { useEffect, useState } from "react";
import Category from "../Category/Category";
import Card from "../Card/Card";
import { API_URL } from "../../config";
import axios from "axios";
import Spinner from "../loader/Spinner";
function Products() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [load, SetLoad] = useState(false);
  const filterItem = (category) => {
 
    if (category === "All") {
      setFilteredProducts(products); // Display all products
    } else {
      const updatedList = products.filter(
        (product) => product.category === category
      );
      setFilteredProducts(updatedList); // Display products of selected category
    }
  };
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        SetLoad(true);
        const res = await axios.get(`${API_URL}/api/getproducts`);
        const data = res.data;
        // console.log(data);
        setProducts(data);
        setFilteredProducts(data);
        const uniqueCategories = [
          "All",
          ...new Set(data.map((product) => product.category)),
          
        ];
        setMenuList(uniqueCategories);
        SetLoad(false);
      } catch (err) {
        console.log("Error ", err);
      }
    };
    fetchProduct();
  }, []);

  return (
    <>
    {load ? (
      <div className="loading-container">
        <Spinner/>
      </div>
    ) : (
      <>
        <Category menuList={menuList} filterItem={filterItem} />
        <Card products={filteredProducts} />
      </>
    )}
  </>
  );
}

export default Products;
