import React, { useState } from "react";
import "./Category.css"; // Keep your existing CSS file for additional styling

const Category = ({ filterItem, menuList }) => {
  const [activeCategory, setActiveCategory] = useState("All");

  const handleClick = (category) => {
    setActiveCategory(category);
    filterItem(category);
  };

  return (
    <div className="category-container">
      <nav className="navbar bg-gray-200 dark:bg-gray-800 py-4 px-6 rounded-md shadow-md">
        <div className="w-95 overflow-x-auto whitespace-nowrap justify-start">
          {menuList.map((curElem) => (
            <button
              key={curElem}
              onClick={() => handleClick(curElem)}
              className={`text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 ${
                activeCategory === curElem && curElem !== "All" ? 'active' : ''
              } ${
                activeCategory === "All" && curElem === "All" ? 'active' : ''
              }`}
            >
              {curElem}
            </button>
          ))}
        </div>
      </nav>
    </div>
  );
};

export default Category;
